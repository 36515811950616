<template>
  <div class="container-fluid">
    <b-jumbotron class="mt-4">
        <template #header>Sold șoferi</template>
    </b-jumbotron>
    <Vuetable
      ref="vuetable"
      :css="css.table"
      :api-url="apiUrl"
      :sort-order="sortOrder"
      :fields="tableFields"
      :http-fetch="fetchApiData"
      :transform="transformApiResponse"
      pagination-path="meta"
      @vuetable:pagination-data="onPaginationData"
    >
    </Vuetable>
    <div class="text-center d-flex justify-content-center mt-3">
      <VuetablePagination
        ref="pagination"
        :css="css.pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></VuetablePagination>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import Vuetable from 'vuetable-2'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import CssConfig from '@/assets/other/VuetableBootstrap4Config'

export default {
  name: 'SoldSoferi',
  components: {
    Vuetable,
    VuetablePagination
  },
  props: {
    usersType: {
      type: String,
      required: true
    }
  },

  mounted () {},
  data () {
    return {
      tableFields: [
        {
          name: 'id',
          title: 'ID'
        },
        {
          name: 'name',
          title: 'Nume'
        },
        {
          name: 'email',
          title: 'Email'
        },
        {
          name: 'phone',
          title: 'Telefon'
        },
        {
          name: 'balance',
          title: 'Sold'
        },
        {
          name: 'discount_balance',
          title: 'Sold (discount)'
        }
      ],
      css: CssConfig
    }
  },
  computed: {
    apiUrl () {
      return config.baseApiUrl + '/api/users?role=driver'
    }
  },
  methods: {
    fetchApiData (apiUrl, httpOptions) {
      return this.$axios.get(apiUrl, httpOptions)
    },
    transformApiResponse (response) {
      return response
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    remove (id) {
      if (confirm('Remove?')) {
        this.$axios.delete(config.baseApiUrl + '/api/users/' + id)
          .then(() => {
            this.getData()
          })
      }
    }
  },
  watch: {
    usersType () {
      this.getData()
    }
  }
}
</script>

<style scoped>
  .table tr th,
  .table tr td {
    vertical-align: middle;
  }
</style>
